@use 'sass:math';

$primary: #49c1b3;
$secondary: #2d3a4d;
$warning: #f27035;
$error: #d32f2f;
$info: #d9d9d9;
$success: #5de4d5;

:export {
  primary: $primary;
  secondary: $secondary;
  warning: $warning;
  error: $error;
  info: $info;
  success: $success;
}

$menuWidth: 250px;
$smallMenuWidth: 150px;
$small: 700px;
$gutter: 1.5rem;
$halfGutter: math.div($gutter, 2);
